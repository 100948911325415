<template>
  <div>
    <v-row justify="center pt-3">
      <v-col cols="12" sm="12" md="12" lg="12" class="pa-2 pb-0">
        <v-form ref="vendorValidateForm" lazy-validation @submit.prevent="submit">
          <v-row class="pb-3">
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder :disabled="!disableValidate" :rules="max10Rule"
                :counter="10" v-model="tempAddressObj.vendno"
                @input="tempAddressObj.vendno = tempAddressObj.vendno?.toUpperCase()" class="ma-0" label="Vendor Number"
                outlined clearable dense>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="1" class="px-5 py-0">
              <v-btn :disabled="disableValidateBtn" @click="onClickValidate" color="primarytext" outlined
                class="mx-auto btn-style" elevation="1">
                Validate
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <!-- row 2 -->
        <v-form ref="AddressForm" lazy-validation @submit.prevent="submit">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder :disabled="disableValidate"
                v-model="tempAddressObj.title" class="ma-0" label="Title" outlined clearable dense
                @input="tempAddressObj.title = tempAddressObj.title?.toUpperCase()">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" :rules="required" persistent-placeholder :disabled="disableValidate"
                v-model="tempAddressObj.last" class="ma-0" label="Name" outlined clearable dense
                @input="tempAddressObj.last = tempAddressObj.last?.toUpperCase()">>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" :disabled="disableValidate" persistent-placeholder
                v-model="tempAddressObj.store" class="ma-0" label="Store/Site/Id" outlined clearable dense
                @input="tempAddressObj.store = tempAddressObj.store?.toUpperCase()">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" :rules="required" :disabled="disableValidate" persistent-placeholder
                v-model="tempAddressObj.company" class="ma-0" label="Company Name" outlined clearable dense
                @input="tempAddressObj.company = tempAddressObj.company?.toUpperCase()">
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Row 3 -->
          <v-row>
            <!-- Adddress 1 -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" :rules="required" :disabled="disableValidate" persistent-placeholder
                v-model="tempAddressObj.address1" class="ma-0" label="Address1" outlined clearable dense
                @input="tempAddressObj.address1 = tempAddressObj.address1?.toUpperCase()">
              </v-text-field>
            </v-col>
            <!-- Address 2 -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" :disabled="disableValidate" v-model="tempAddressObj.address2"
                persistent-placeholder class="ma-0" label="Address2" outlined clearable dense
                @input="tempAddressObj.address2 = tempAddressObj.address2?.toUpperCase()">
              </v-text-field>
            </v-col>
            <!-- City -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" :rules="required" :disabled="disableValidate" persistent-placeholder
                v-model="tempAddressObj.city" label="City" class="ma-0" outlined clearable dense
                @input="tempAddressObj.city = tempAddressObj.city?.toUpperCase()">
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Row 4 -->
          <v-row>
            <!-- State -->
            <v-col v-if="showStateDrop" cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-autocomplete color="primarytext" :rules="required" :disabled="disableValidate" persistent-placeholder
                v-model="tempAddressObj.state" class="ma-0" :items="stateData" item-text="States" label="State" outlined
                item-value="StateKey" dense>
              </v-autocomplete>
            </v-col>
            <!-- State if dropdown is empty -->
            <v-col v-else cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder :rules="stateRule" :disabled="disableValidate"
                v-model="tempAddressObj.state" class="ma-0" label="State" name="State" outlined clearable dense
                @input="tempAddressObj.state = tempAddressObj.state?.toUpperCase()">
              </v-text-field>
            </v-col>
            <!-- zip -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder :rules="required" :disabled="disableValidate"
                v-model="tempAddressObj.zip" label="Zip Code" outlined class="ma-0" clearable dense>
              </v-text-field>
            </v-col>
            <!-- COuntry -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-autocomplete color="primarytext" persistent-placeholder :rules="required" :disabled="disableValidate"
                attach outlined v-model="tempAddressObj.country" :items="countryData" item-text="Country"
                item-value="CountryKey" label="Country" dense class="ma-0" @change="countrySelected('new')">
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- Row 5 -->
          <v-row>
            <!-- phone11 1 -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder
                :rules="tempAddressObj.phone1?.length > 0 || tempAddressObj.cell?.length > 0 ? [] : required"
                :disabled="disableValidate" v-model="tempAddressObj.phone1" class="ma-0" label="Phone1" outlined
                clearable dense>
              </v-text-field>
            </v-col>
            <!-- phone1 2 -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder :disabled="disableValidate"
                v-model="tempAddressObj.phone2" class="ma-0" label="Phone2" outlined clearable dense>
              </v-text-field>
            </v-col>
            <!-- Fax -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder :disabled="disableValidate"
                v-model="tempAddressObj.fax" class="ma-0" label="Fax" outlined clearable dense>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Row 6 -->
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder
                :rules="tempAddressObj.phone1?.length > 0 || tempAddressObj.cell?.length > 0 ? [] : required"
                :disabled="disableValidate" v-model="tempAddressObj.cell" class="ma-0" label="Cell" outlined clearable
                dense>
              </v-text-field>
            </v-col>
            <!-- Email -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" persistent-placeholder :rules="emailRule" :disabled="disableValidate"
                v-model="tempAddressObj.email" class="ma-0" label="Email" outlined clearable dense>
              </v-text-field>
            </v-col>
            <!-- submit -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-btn @click="submitAddress(compName)" color="primarytext" outlined class="mx-auto btn-style"
                elevation="1">
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import commonPoService from './js/common-po-service';
import EncryptUtility from '../utility/js/encrypt-utility';
import commonApiService from '../common/js/common-api-service';
export default {
  name: 'Address',
  props: {
    compName: String,
    proj_key: String,
    editJSON: Object,
  },
  data() {
    return {
      max10Rule: [
        (v) => !!v || 'Field is required',
        (v) =>
          (v || '').length <= 10 ||
          'Max Length must be 10 characters or less',
      ],
      stateRule: [(v) => !!v || 'Field is required',
      (v) =>
        /^[a-zA-Z0-9 ]+$/.test(v) ||
        'special character are not allowed'
      ],
      required: [(v) => !!v || 'Field is required'],
      emailRule: [(v) =>
        /.+@.+\..+/.test(v) ||
        'Enter valid Email'],
      sameAsCust: false,
      sameAsReturn: false,
      sameAsShip: false,
      showAddFreature: false,
      disableValidate: true,
      disableValidateBtn: false,
      tempAddressObj: {
        store: "",
        fax: "",
        phone2: "",
        Name: "",
        phone1: "",
        email: "",
        company: "",
        country: "",
        state: "",
        last: "",
        first: "",
        title: "",
        address2: "",
        address1: "",
        city: "",
        zip: "",
        cell: "",
      },
      countryData: [],
      stateData: [],
      userId: EncryptUtility.localStorageDecrypt('userID'),
      showStateDrop: false,
    }
  },
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await commonApiService.countryListNew(this.userId, "", 1, "get");
    this.countryData = data.CountryInfo;
    if (this.compName !== "EditRemit" && this.compName !== "EditVendor") {
      this.tempAddressObj.country = "USA"
      this.countrySelected("new");
    } else {
      this.countrySelected();
    }
  },
  mounted() {
    if (this.compName == "EditVendor") {
      this.disableValidate = false;
      this.disableValidateBtn = true;
      let tempAddress = JSON.parse(JSON.stringify(this.editJSON));
      this.tempAddressObj = JSON.parse(JSON.stringify(tempAddress));
      this.countrySelected();
    }
    else if (this.compName == "EditRemit") {
      this.disableValidate = false;
      this.disableValidateBtn = true;
      let tempAddress = JSON.parse(JSON.stringify(this.editJSON));
      this.tempAddressObj = JSON.parse(JSON.stringify(tempAddress));
      this.countrySelected();
    }
  },
  destroyed() {
    this.clearAddress()
  },
  methods: {
    // To get the data of Dropdowns
    async submitAddress(name) {
      if (this.$refs.AddressForm.validate()) {
        if (name == "AddVendor" || name == "EditVendor") {
          let tempObj = JSON.parse(JSON.stringify(this.tempAddressObj))
          this.$emit('updatedAPI', tempObj, "vendor");
        }
        else if (name == "AddRemit") {
          let tempObj = JSON.parse(JSON.stringify(this.tempAddressObj))
          this.$emit('updatedAPI', tempObj, "remit");
        }
        else if (name == "EditRemit") {
          let tempObj = JSON.parse(JSON.stringify(this.tempAddressObj))
          this.$emit('updatedAPI', tempObj, "editRemit");
        }
        this.$refs.AddressForm.resetValidation()
        this.$refs.AddressForm.reset()
        this.$refs.vendorValidateForm.resetValidation()
        this.$refs.vendorValidateForm.reset()
        this.clearAddress()
      }
    },
    // to validate vendor date
    async onClickValidate() {
      if (this.compName == "AddVendor") {
        if (this.$refs.vendorValidateForm.validate()) {
          let obj = {
            UserId: this.userId,
            VendNo: this.tempAddressObj.vendno,
            Pass: 1,
            proj_id: this.proj_key
          }
          let validateData = await commonPoService.getValidateVendor("post", obj, true)
          if (validateData[0]?.Message)
            this.disableValidate = false
        }
      }
      else if (this.compName == "AddRemit") {
        if (this.$refs.vendorValidateForm.validate()) {
          let obj = {
            UserId: this.userId,
            VendNo: this.tempAddressObj.vendno,
            Pass: 2,
            proj_id: this.proj_key
          }
          let validateData = await commonPoService.getValidateVendor("post", obj, true)
          if (validateData[0]?.Message)
            this.disableValidate = false
        }
      }
      else {
        if (this.$refs.vendorValidateForm.validate()) {
          let validateData = await commonPoService.getValidateReturn("get", this.userId, this.customerNum, this.proj_key)
          if (validateData[0]?.Message)
            this.disableValidate = false
        }
      }
    },
    //Country Selected
    async countrySelected(state) {
      if (state == "new") {
        this.tempAddressObj.state = "";
      } else {
        let tempAddress = JSON.parse(JSON.stringify(this.editJSON));
        this.tempAddressObj = JSON.parse(JSON.stringify(tempAddress));
      }
      let data1 = await commonApiService.countryListNew(this.userId, this.tempAddressObj.country, 2, "get");
      this.stateData = data1.StateInfo;
      if (this.stateData.length !== 0) {
        this.showStateDrop = true;
      } else {
        this.showStateDrop = false;
      }
    },
    clearAddress() {
      this.tempAddressObj = {
        store: "",
        fax: "",
        phone2: "",
        Name: "",
        phone1: "",
        email: "",
        company: "",
        country: "",
        state: "",
        last: "",
        first: "",
        title: "",
        address2: "",
        address1: "",
        city: "",
        zip: "",
      }
    }
  }
}
</script>