import addressComp from "../address-comp.vue";
import commonApiService from "../../common/js/common-api-service";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import AddFavourite from "../../utility/js/addFav";
//import salesService from '../../sales-order/js/sales-order-service';
import moment from "moment";
import BackToTop from "../../common/back-to-top.vue";
import { bus } from "../../../main";
import commonPoService from "./common-po-service";
import Utility from "../../../shared/utility";
export default {
  data() {
    return {
      purchaseOrderId: "",
      stepElement: 1,
      //
      //Search Keys
      venSearchKey: "",
      receiveSearchKey: "",
      remitSearchKey: "",
      partReturnSearchKey: "",
      partShipSearchKey: "",
      //BreadCrumb Data
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: "",
      subPageUrl: "",
      appPageName: "",
      route: "",
      backSlashParent: true,
      backSlash: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      vendorList: [],
      remitList: [],
      receiveList: [],
      businessList: [],
      orderTypeList: [],
      paymentList: [],
      LineTypeList: [],
      returnResultList: [],
      partResultList: [],
      shipWareList: [],
      purchaseOrderHistoryItems: [],
      returnCarrierList: [],
      shipCarrierList: [],
      returnWareList: [],
      showActionsList: [],
      shippedDataList: [],
      receivedDataList: [],
      pickedDataList: [],
      shipCosmeticList: [],
      receiveCosmeticList: [],
      selectedLink: "",
      warningText: "",
      custSearchKey: "",

      panel: [0, 1, 2, 3, 4],
      //disables flags
      disableVendor: false,
      showVenAddress: false,
      disableEmitTo: false,
      disableReceiveAdd: false,
      disableEmitAdd: false,
      disablePurchaseForm: true,
      showEditVendor: false,
      projectName: "",
      businessName: "",
      sameAsVendorCheck: false,
      showEmitDDL: false,
      showReceivedDDL: false,
      showAddressDialog: false,
      showEditRemit: false,
      disableShippingSection: false,
      showFlexDialog: false,
      showLineDelete: false,
      showUpdateLine: false,
      deleteDialog: false,
      deleteFlexItem: false,
      disableNotes: false,
      deleteLine: false,
      showLineItem: false,
      disableLine: false,
      showPartRetDetails: false,
      showexpIcon: false,
      showLineUpdate: false,
      showPartsItem: false,
      bookmark: false,
      hold: false,
      showHoldBtn: false,
      showWarningDialog: false,
      holdBtn: false,
      releaseBtn: false,
      showPartRet: false,
      showPartShip: false,
      purchaseOrderHistoryDialog: false,
      showLinkDialog: false,

      // Purchase Order Array
      purchaseOrderJson: {
        proj_id: "",
        outbound_order_status: "",
        inbound_order_status: "",
        vendorInfo: [
          {
            first: "",
            title: "",
            last: "",
            vendno: "",
            vendor_id: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone1: "",
            phone2: "",
            cell: "",
            email: "",
            fax: "",
          },
        ],
        remitInfo: [
          {
            title: "",
            last: "",
            first: "",
            vendno: "",
            vendor_id: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone1: "",
            phone2: "",
            cell: "",
            email: "",
            fax: "",
          },
        ],
        receiveInfo: [
          {
            title: "",
            first: "",
            last: "",
            vendno: "",
            vendor_id: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone1: "",
            phone2: "",
            cell: "",
            email: "",
            fax: "",
          },
        ],
        auto_generate_order_number: false,
        order_number: "",
        order_status: "",
        order_type_id: "",
        order_type: "",
        bu_id: "",
        rma: "",
        vendor_so: "",
        ordered_date: new Date(),
        delivery_date: new Date(),
        pay_term_id: "",
        fob: "",
        vendor_order_number: "",
        step_number: "",
        saturday_delivery: false,
        signature_required: false,
        outbound_carrier: "",
        inbound_carrier: "",
        order_notes: "",
        flex_field: [],
        order_line: [],
      },
      //Table Headers
      flexHeader: [
        {
          text: "Flex Attribute",
          align: "start",
          value: "flex_code",
          class: "primary customwhite--text",
        },
        {
          text: "Flex Value",
          value: "flex_data",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      lineItemHeader: [
        {
          text: "Line#",
          align: "start",
          value: "sequence_number",
          class: "primary customwhite--text",
        },
        {
          text: "Line Type",
          value: "order_line_text",
          class: "primary customwhite--text",
        },
        {
          text: "Part Number To Ship",
          value: "sh_part_number",
          class: "primary customwhite--text",
        },
        {
          text: "Receive Part Number",
          value: "receive_part_number",
          class: "primary customwhite--text",
        },

        {
          text: "Outbound Warehouse",
          value: "sh_from_warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Inbound Warehouse",
          value: "receive_in_warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Ordered Qty",
          value: "quantity",
          class: "primary customwhite--text",
        },
        { text: "Received", value: "Rcvd", class: "primary customwhite--text" },
        { text: "Picked", value: "Picked", class: "primary customwhite--text" },
        {
          text: "Returned",
          value: "Shipped",
          class: "primary customwhite--text",
        },
        {
          text: "Open Returns",
          value: "Open_Shipments",
          class: "primary customwhite--text",
        },
        {
          text: "Open Receipts",
          value: "Open_Receipts",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
        },
      ],
      headersOrderHistory: [
        {
          text: "Action",
          align: "start",
          value: "Action",
          class: "primary customwhite--text",
        },
        { text: "Logged", value: "Logged", class: "primary customwhite--text" },
        { text: "User", value: "User", class: "primary customwhite--text" },
      ],
      headersPicked: [
        {
          text: "PO Line#",
          align: "start",
          value: "PO Line#",
          class: "primary customwhite--text",
        },
        {
          text: "Part No",
          value: "PartNo",
          class: "primary customwhite--text",
        },
        {
          text: "BCN",
          value: "BCN",
          class: "primary customwhite--text",
        },
        {
          text: "Serial#",
          value: "Serial#",
          class: "primary customwhite--text",
        },
        {
          text: "ESN",
          value: "ESN",
          class: "primary customwhite--text",
        },
        {
          text: "Picked",
          value: "Picked",
          class: "primary customwhite--text",
        },
        {
          text: "From Bin (LPN)",
          value: "From Bin (LPN)",
          class: "primary customwhite--text",
        },
        {
          text: "To LPN",
          value: "To LPN",
          class: "primary customwhite--text",
        },
        {
          text: "Qty",
          value: "Qty",
          class: "primary customwhite--text",
        },

      ],
      headersReceived: [
        {
          text: "PO Line#",
          align: "start",
          value: "PO Line#",
          class: "primary customwhite--text",
        },
        {
          text: "Receiver#",
          value: "Receiver#",
          class: "primary customwhite--text",
        },
        {
          text: "Part No",
          value: "Part No",
          class: "primary customwhite--text",
        },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        {
          text: "Serial",
          value: "Serial#",
          class: "primary customwhite--text",
        },
        { text: "ESN", value: "ESN", class: "primary customwhite--text" },
        { text: "Date", value: "Date", class: "primary customwhite--text" },
        { text: "Tech", value: "Tech", class: "primary customwhite--text" },
        {
          text: "Packing Slip",
          value: "Packing Slip",
          class: "primary customwhite--text",
        },
        {
          text: "Waybill#",
          value: "Waybill#",
          class: "primary customwhite--text",
        },
        { text: "Qty", value: "Qty", class: "primary customwhite--text" },
        { text: "Cost", value: "Cost", class: "primary customwhite--text" },
        {
          text: "Ext Cost",
          value: "Ext Cost",
          class: "primary customwhite--text",
        },
      ],
      headersShipped: [
        {
          text: "PO Line#",
          align: "start",
          value: "PO Line#",
          class: "primary customwhite--text",
        },
        {
          text: "Shipper#",
          value: "Shipper#",
          class: "primary customwhite--text",
        },
        {
          text: "Part No",
          value: "Part No",
          class: "primary customwhite--text",
        },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        {
          text: "Serial",
          value: "Serial#",
          class: "primary customwhite--text",
        },
        { text: "ESN", value: "ESN", class: "primary customwhite--text" },
        {
          text: "Shipped",
          value: "Shipped",
          class: "primary customwhite--text",
        },
        { text: "Tech", value: "Tech", class: "primary customwhite--text" },
        {
          text: "Packing Slip",
          value: "Packing Slip",
          class: "primary customwhite--text",
        },
        {
          text: "Waybill#",
          value: "Waybill#",
          class: "primary customwhite--text",
        },
        { text: "Qty", value: "Qty", class: "primary customwhite--text" },
        { text: "Cost", value: "Cost", class: "primary customwhite--text" },
        {
          text: "Ext Cost",
          value: "Ext Cost",
          class: "primary customwhite--text",
        },
      ],
      headersWarranty: [
        {
          text: "Serial No",
          align: "start",
          value: "serial_number",
          class: "primary customwhite--text",
        },
        {
          text: "Problem Description",
          value: "problem_description",
          class: "primary customwhite--text",
        },
        {
          text: "Tracking",
          value: "return_tracking_number",
          class: "primary customwhite--text",
        },
        {
          text: "Warranty",
          value: "warranty_status",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      temp_order_line: {
        line_id: "",
        sequence_number: "",
        order_line_type: "",
        line_number: "",
        sh_part_id: "",
        sh_part_number: "",
        sh_part_description: "",
        sh_from_warehouse_id: "",
        sh_from_warehouse: "",
        sh_from_inventory_bucket: "",
        sh_quantity: "",
        sh_ship_by_date: "",
        po_line_ref: "",
        sh_grade: "",
        receive_part_id: "",
        receive_part_number: "",
        receive_part_description: "",
        receive_in_warehouse_id: "",
        receive_in_warehouse: "",
        receive_in_inventory_bucket: "",
        receive_quantity: "",
        receive_unit_cost: "0.00",
        receive_rma: "",
        receive_grade: "",
        receive_delivery_date: "",
        receive_ext_cost: "",
        expected_serial_number: [],
        line_comment: "",
        vendor_part_number: "",
        flex_field: [],
        // Add by Dev team for showing text
        order_line_text: "",
        showPartRet: false,
        showPartShip: false,
        showBuild: "",
        showSerial: "",
      },
      flex_field_Att: "",
      flex_field_value: "",
      sequence_number: 0,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "").split("/")[0];
    //to Load Data
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    // this.actionList = data?.actionList;

    this.mainPage = data?.mainPage;
    this.favouriteList = data?.favouriteList;
    this.pageUrl = data?.pageUrl;
    this.subPage = data?.subPage;
    this.showAdd = data?.showAdd;
    this.subPageUrl = data?.subPageUrl;
    this.appPageName = data?.appPageName;
    //New BreadCrumb format COde DO NOT Remove IT
    let actionData = await AddFavourite.getParentPageAction(
      this.userId,
      this.subPageUrl
    );
    this.actionList = actionData?.actionList;

    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    // to load the Project Dropdown
    let projectData = await commonApiService.getProject("get", this.userId);
    this.projectList = projectData?.ProjectInfo;
    this.purchaseOrderId = atob(this.$route.params.purchaseOrderId);
    this.purchaseOrderDetails = await commonPoService.getPurchaseOrderDetails(
      "get",
      this.userId,
      this.purchaseOrderId
    );
    // Assigning the values we get from API to the Form JSON we created
    this.purchaseOrderJson = JSON.parse(
      JSON.stringify(this.purchaseOrderDetails.Result[0])
    );

    this.setActionFunction();

    let businessObj = {
      UserId: this.userId,
      bu_key: this.purchaseOrderJson.bu_id,
      pass: 2, // Do not Delete
    };
    this.getOrderTypeData(businessObj);
    let carrierObj = {
      UserId: this.userId,
      bu_key: this.purchaseOrderJson.bu_id,
      vendso: this.purchaseOrderJson.vendno,
      order_type: this.purchaseOrderJson.order_type_id,
      sbu_key: 0,
    };
    this.getCarrierData(carrierObj);
    this.custSearchKey = this.purchaseOrderJson.vendorInfo[0].company;
    this.shipSearchKey = this.purchaseOrderJson.remitInfo[0].company;
    this.returnSearchKey = this.purchaseOrderJson.receiveInfo[0].company;
    this.hold = this.purchaseOrderJson.is_hold;
    this.showHoldBtn = Boolean(this.purchaseOrderJson.enable_hold);
    this.purchaseOrderJson.cdate = this.purchaseOrderJson?.cdate
      ? Utility.convertESTToLocal(this.purchaseOrderJson.cdate)
      : Utility.convertESTToLocal(new Date());
    this.purchaseOrderJson.udate = this.purchaseOrderJson?.udate
      ? Utility.convertESTToLocal(this.purchaseOrderJson.udate)
      : Utility.convertESTToLocal(new Date());
    this.purchaseOrderJson.ordered_date = this.purchaseOrderJson.ordered_date ?
      Utility.convertESTToLocalDate(this.purchaseOrderJson.ordered_date) : "";
    if (this.purchaseOrderJson.delivery_date == "1900-01-01T00:00:00" || !this.purchaseOrderJson.delivery_date)
      this.purchaseOrderJson.delivery_date = ""
    else {
      this.purchaseOrderJson.delivery_date =
        Utility.convertESTToLocalDate(this.purchaseOrderJson.delivery_date);
    }
    this.bookmark = Boolean(this.purchaseOrderJson.is_bookmark);
    this.showActionsList.push({
      Id: "PO-History",
      Icon: "mdi mdi-account-clock-outline",
      FriendlyName: "PO History",
    });
    if (this.bookmark)
      this.showActionsList.push({
        Id: "PO-BookMark-Remove",
        Icon: "mdi mdi-bookmark-outline",
        FriendlyName: "Remove Bookmark",
      });
    else
      this.showActionsList.push({
        Id: "PO-BookMark",
        Icon: "mdi mdi-bookmark",
        FriendlyName: "Add Bookmark",
      });

    LoaderDialog.visible = false;
    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
  },
  // For Reload Functionality

  computed: {
    computedDateFormattedMomentjs() {
      return this.purchaseOrderJson.ordered_date
        ? moment(String(this.purchaseOrderJson.ordered_date)).format(
          "MM/DD/YYYY"
        )
        : moment(String(new Date())).format("MM/DD/YYYY");
    },
    shipcomputeDateFormattedMomentjs() {
      return this.purchaseOrderJson.delivery_date
        ? moment(String(this.purchaseOrderJson.delivery_date)).format(
          "MM/DD/YYYY"
        )
        : "";
    },

    tempDelDatecomputeDateFormattedMomentjs() {
      return this.temp_order_line.receive_delivery_date
        ? moment(String(this.temp_order_line.receive_delivery_date)).format(
          "MM/DD/YYYY"
        )
        : "";
    },
    shipByDateDatecomputeDateFormattedMomentjs() {
      return this.temp_order_line.sh_ship_by_date
        ? moment(String(this.temp_order_line.sh_ship_by_date)).format(
          "MM/DD/YYYY"
        )
        : "";
    },
  },
  mounted() { },
  methods: {
    setActionFunction() {
      if (
        this.actionSetter("EditPurchaseOrder") &&
        Boolean(this.purchaseOrderJson.enable_edit_po)
      )
        this.showActionsList.push({
          Id: "PO-Edit",
          Icon: "mdi-pencil",
          FriendlyName: "PO Edit",
        });
    },
    redirectPage() {
      this.$router.push(
        `/purchase-order-edit/${btoa(this.purchaseOrderId.toString())}`
      );
    },
    // Receive Part Number
    async receivePartNumber() {
      if (this.partReturnSearchKey) {
        if (this.partReturnSearchKey.length > 2) {
          this.showPartRetDetails = true;
          let returnSerachObj = {
            UserId: this.userId,
            SearchStr: this.partReturnSearchKey,
            order_bu_key: this.purchaseOrderJson.bu_id,
          };
          let returnSearchData = await commonPoService.postReturnSearch(
            "post",
            returnSerachObj,
            false,
            true
          );
          if (returnSearchData != "")
            this.returnResultList = returnSearchData?.Resultset;
        }
      }
    },
    async getCarrierData(carrierObj) {
      let carrierData = await commonPoService.postCarrierData(
        "post",
        carrierObj
      );
      this.returnCarrierList = carrierData.InboundSLs;
      this.shipCarrierList = carrierData.OutboundSLs;
    },
    clearPartReceive() {
      this.temp_order_line.receive_part_number = "";
      this.temp_order_line.receive_part_description = "";
      this.temp_order_line.receive_rma = "";
      this.temp_order_line.receive_price = "0.00";
      this.temp_order_line.receive_in_inventory_bucket = "";
      this.temp_order_line.receive_in_warehouse = "";
      this.temp_order_line.receive_in_warehouse_id = "";
      this.returnWareList = [];
      this.expected_serial_number = [];
    },
    // Get oRder Type List
    async getOrderTypeData(object) {
      let orderTypeData = await commonPoService.getOrderTypeList(
        "post",
        object
      );
      if (orderTypeData?.OrderTypes) {
        this.notRequired = true;
        this.orderTypeList = orderTypeData.OrderTypes.map((x) => ({
          ord_code: x.ord_code,
          ordCodedesc: x.ordCodedesc,
        }));
      } else {
        this.notRequired = false;
        this.disableAuto = false;
      }
      if (orderTypeData?.PaymentTerms) {
        this.paymentList = orderTypeData.PaymentTerms.map((x) => ({
          payterm_id: x.list_id,
          payterm_desc: x.displaystring,
        }));
      }
    },
    //common Function for Warehouse
    async getWarehouseData(shipObject) {
      let shipWarehouseList = await commonPoService.postWarehouseData(
        "post",
        shipObject
      );
      if (shipWarehouseList?.ShipWare) {
        this.shipWareList = shipWarehouseList.ShipWare.map((x) => ({
          ware: x.ware,
          ware_id: x.ware_id + "_" + x.bucket,
          bucket: x.bucket,
          from_warebuck: x.from_warebuck,
          mess_bit: x.mess_bit,
          aqty: x.aqty,
          show_text: x.show_text,
          cg_desc: x.cg_desc,
          rm_id: x.rm_id,
        }));
        let cosmeticData = this.shipWareList.filter(
          (x) => x.ware_id == this.temp_order_line.sh_from_warehouse_id
        );
        this.shipCosmeticList = cosmeticData.map((x) => ({
          cg_desc: x.cg_desc,
          rm_id: x.rm_id,
        }));
        if (this.temp_order_line.sh_grade == 0) {
          let calShGrade = this.shipCosmeticList.filter(
            (x) => x.cg_desc == "No Grade"
          );
          if (calShGrade.length > 0) {
            this.temp_order_line.sh_grade = calShGrade[0].rm_id;
          }
        }
      }
      if (shipWarehouseList?.ReceiveWare != "") {
        this.returnWareList = shipWarehouseList?.ReceiveWare?.map((x) => ({
          ware: x.ware,
          ware_id: x.ware_id + "_" + x.bucket,
          bucket: x.bucket,
          into_warebuck: x.into_warebuck,
          cg_desc: x.cg_desc,
          rm_id: x.rm_id,
        }));
        let cosmeticData = this.returnWareList.filter(
          (x) => x.ware_id == this.temp_order_line.receive_in_warehouse_id
        );
        this.receiveCosmeticList = cosmeticData.map((x) => ({
          cg_desc: x.cg_desc,
          rm_id: x.rm_id,
        }));
        if (this.temp_order_line.receive_grade == 0) {
          let calReceiveGrade = this.receiveCosmeticList.filter(
            (x) => x.cg_desc == "No Grade"
          );
          if (calReceiveGrade.length > 0) {
            this.temp_order_line.receive_grade = calReceiveGrade[0].rm_id;
          }
        }
      }
    },
    //commone Function for Line item Get Datass
    async getLineTypeData(obj) {
      let LineTypeData = await commonPoService.postOrderTypeData("post", obj);
      this.temp_order_line.delivery_date = this.purchaseOrderJson.delivery_date;
      if (LineTypeData?.LineTypeInfo) {
        this.LineTypeList = LineTypeData.LineTypeInfo.map((x) => ({
          so_type_id: x.sotype_id,
          so_desc: x.so_desc,
          so_type: x.so_type,
          to_ship: x.to_ship,
          to_return: x.to_return,
          to_disable_quantity: x.to_disable_quantity,
          to_disable_ReturnSel: x.to_disable_ReturnSel,
        }));
      }

    },
    //Action List

    // Action based
    actionSetter(action) {
      if (
        this.actionList !== undefined &&
        this.actionList !== null &&
        this.actionList !== ""
      ) {
        let filter = this.actionList.filter(
          (entry) => entry.SystemName === action
        );
        return filter !== undefined && filter !== null && filter.length == 1
          ? true
          : false;
      } else return false;
    },
    //Sales Order History button click event
    async purchaseOrderHistory() {
      let historyData = await commonPoService.getPOActionLog(
        this.purchaseOrderId,
        this.userId
      );
      if (historyData !== null && historyData !== undefined) {
        const newArr = historyData.ActionLogInfo.map((obj) => {
          return {
            ...obj,
            Logged: Utility.convertESTToLocal(obj.Logged),
          };
        });
        this.OrderHistoryItems = newArr;
        this.OrderHistoryDialog = true;
      }
    },

    // To get warrenty Data
    async warrentyData() {
      let warrentyListData = await commonPoService.getWarrentyData(
        "get",
        this.userId
      );
      if (warrentyListData != "") {
        this.warrentyList = warrentyListData.WarrantyData;
      }
    },
    // On click of Add List
    // To View hyperLinks
    async onClickedHyperLink(value, item) {

      this.selectedLink = value;
      if (value === "Picked") {
        let pickedData = await commonPoService.getPurchaseOrderPickedData(
          "get",
          this.purchaseOrderId,
          item.line_number,
          this.userId
        );
        this.pickedDataList = pickedData.Data;
        this.pickedDataList.forEach((element) => {
          element.Picked = Utility.convertESTToLocal(element.Picked);
          this.showLinkDialog = true;
        });
      } else if (value === "Received") {
        let receivedData = await commonPoService.getPurchaseOrderReceivedData(
          "get",
          this.purchaseOrderId,
          item.line_number,
          this.userId
        );
        this.receivedDataList = receivedData.Data;
        this.receivedDataList.forEach((element) => {
          element.Date = Utility.convertESTToLocal(element.Date);
          this.showLinkDialog = true;
        });
      } else if (value === "Shipped") {
        let shippedData = await commonPoService.getPurchaseOrderShippedData(
          "get",
          this.purchaseOrderId,
          item.line_number,
          this.userId
        );
        this.shippedDataList = shippedData.Data;
        this.shippedDataList.forEach((element) => {
          element.Shipped = Utility.convertESTToLocal(element.Shipped);
          this.showLinkDialog = true;
        });
      }
    },

    // Edit Line List
    //View Line Item Details
    async lineItemsView(item) {
      this.panel = []
      this.temp_order_line.sh_ship_by_date = this.temp_order_line.sh_ship_by_date ?
        Utility.convertESTToLocalDate(this.temp_order_line.sh_ship_by_date) : ""
      let lineItemData = {};
      this.showLineItem = true;
      this.showLineUpdate = true;
      if (item.order_line_type == 1) {
        this.panel = [0];
      }
      else if (item.order_line_type == 8) {
        this.panel = [1];
      }
      else {
        this.panel = [0, 1];
      }
      this.shipWareList = [];
      this.returnWareList = [];
      this.shipPartKey = item.sh_part_id;
      if (item.is_Added || item.isEdited) {
        this.temp_order_line = JSON.parse(
          JSON.stringify(
            this.purchaseOrderJson?.order_line.filter(
              (x) => x.line_number == item.line_number
            )[0]
          )
        );
      } else {
        lineItemData = await commonPoService.getLineItemData(
          "get",
          this.userId,
          item.line_id
        );
        this.temp_order_line = JSON.parse(
          JSON.stringify(lineItemData.LineItem[0])
        );
      }
      this.expected_serial_number_list = JSON.parse(
        JSON.stringify(this.temp_order_line.expected_serial_number)
      ).filter((x) => x.is_Deleted == false);
      this.showPartDetails = true;
      this.temp_order_line.receive_in_warehouse_id =
        this.temp_order_line.receive_in_warehouse_id +
        "_" +
        this.temp_order_line.receive_in_inventory_bucket;
      this.temp_order_line.receive_ext_cost =
        this.temp_order_line.receive_unit_cost *
        this.temp_order_line.receive_quantity;
      this.partShipSearchKey = this.temp_order_line.sh_part_number;
      this.temp_order_line.sh_from_warehouse_id =
        this.temp_order_line.sh_from_warehouse_id +
        "_" +
        this.temp_order_line.sh_from_inventory_bucket;

      this.showPartsItem = true;
      this.showLineDelete = true;
      let object = {
        ProjKey: this.purchaseOrderJson.proj_id,
        UserId: this.userId,
        POId: this.purchaseOrderId,
        line_id: 0,
      };

      this.getLineTypeData(object);
      this.showPartRet = lineItemData.LineItem[0].config_det[0].enable_left
        ? lineItemData.LineItem[0].config_det[0].enable_left
        : this.temp_order_line.showPartRet;
      this.showPartShip = lineItemData.LineItem[0].config_det[0].enable_right
        ? lineItemData.LineItem[0].config_det[0].enable_right
        : this.temp_order_line.showPartShip;
      this.partReturnSearchKey = this.temp_order_line.receive_part_number;
      if (this.showPartRet) {
        let warehouseObj = {
          part_num: this.temp_order_line.receive_part_number,
          potype_key: this.temp_order_line.order_line_type,
          order_bu_key: this.purchaseOrderJson.bu_id,
          order_type: this.purchaseOrderJson.order_type_id,
          po_key: this.purchaseOrderId,
          UserId: this.userId,
          loca_key: 0,
          line_id: this.temp_order_line?.line_Id,
        };
        this.getWarehouseData(warehouseObj);
      } else {
        let warehouseObj = {
          part_num: this.temp_order_line.sh_part_number,
          potype_key: this.temp_order_line.order_line_type,
          order_bu_key: this.purchaseOrderJson.bu_id,
          order_type: this.purchaseOrderJson.order_type_id,
          po_key: this.purchaseOrderId,
          UserId: this.userId,
          loca_key: 0,
          line_id: this.temp_order_line?.line_Id,
        };
        this.getWarehouseData(warehouseObj);
      }
      if (this.temp_order_line?.receive_delivery_date == "1900-01-01T00:00:00" || !this.temp_order_line?.receive_delivery_date)
        this.temp_order_line.receive_delivery_date = ""
      else {
        this.temp_order_line.receive_delivery_date =
          Utility.convertESTToLocalDate(this.temp_order_line?.receive_delivery_date)
      }

      if (this.temp_order_line.sh_ship_by_date == "1900-01-01T00:00:00" || !this.temp_order_line.sh_ship_by_date)
        this.temp_order_line.sh_ship_by_date = ""
      else {
        this.temp_order_line.sh_ship_by_date =
          Utility.convertESTToLocalDate(this.temp_order_line?.sh_ship_by_date)
      }
    },

    //Cancel Line Item
    cancelLineItems() {
      this.showLineItem = false;
      this.editLinePart = true;
    },
    // On Click Add Part List Item
    flexDetails(item) {
      this.showFlexDialog = true;
      this.sequence_number = item.sequence_number;
      this.flex_field_Att = item.flex_code;
      this.flex_field_value = item.flex_data;
      this.showLineDelete = true;
      this.showUpdateLine = true;
    },
    //Clear Flex
    clearFlexItem() {
      this.showFlexDialog = false;
      this.flex_field_Att = "";
      this.flex_field_value = "";
      this.$refs.flexForm.resetValidation();
    },
    // on Change LIne Typeh
    // On Chnage Part SHip WareHouse DropDOwn
    //Reset Data to API Call JSON

    // Add BookMark
    async addBookmark() {
      let resp = await commonPoService.addBookMark(
        "get",
        this.userId,
        this.purchaseOrderId,
        true
      );
      if (resp?.Result) {
        this.bookmark = true;
        this.showActionsList = this.showActionsList.filter(
          (item) => item.Id != "PO-BookMark"
        );
        this.showActionsList.unshift({
          Id: "PO-BookMark-Remove",
          Icon: "mdi mdi-bookmark-outline",
          FriendlyName: "Remove Bookmark",
        });
      } else this.bookmark = false;
      bus.$emit("bookmark", this.bookmark);
    },
    //remove BookMark
    async removeBookmark() {
      let resp = await commonPoService.getDeleteBookmarkPO(
        "get",
        this.userId,
        this.purchaseOrderId,
        true
      );
      if (resp?.Result) {
        this.bookmark = false;
        this.showActionsList = this.showActionsList.filter(
          (item) => item.Id != "PO-BookMark-Remove"
        );
        this.showActionsList.unshift({
          Id: "PO-BookMark",
          Icon: "mdi mdi-bookmark",
          FriendlyName: "Add Bookmark",
        });
      } else this.bookmark = true;
      bus.$emit("bookmark", this.bookmark);
    },

    // onClick Picked
    async orderHistory() {
      let historyData = await commonPoService.getPOActionLog(
        this.purchaseOrderId,
        this.userId
      );
      if (historyData !== null && historyData !== undefined) {
        if (historyData?.ActionLogInfo?.length > 0) {
          const newArr = historyData?.ActionLogInfo?.map((obj) => {
            return {
              ...obj,
              Logged: Utility.convertESTToLocal(obj.Logged),
            };
          });
          this.purchaseOrderHistoryItems = newArr;
          this.purchaseOrderHistoryDialog = true;
        }
        else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "No history available",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
  },
  components: {
    breadcrumbComp,
    addressComp,
    BackToTop,
  },
};
